<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    size?: string
    clickable?: boolean
  }>(),
  {
    size: 'md',
    clickable: false,
  }
)

const addSpans: Record<string, unknown> = {
  // アイコンが追加された際に、demo.htmlを見てspanタグが必要な場合は下記に追加
  circle_check: 2,
  delete2: 2,
  exclamation_filled: 2,
  nouser: 2,
  serviceicon_LINE: 2,
  sort_up: 2,
  sort_down: 2,
  'icon-plus': 2,
  add_video: 2,
  menu_up: 2,
  menu_down: 2,
  serviceicon_payment: 3,
  serviceicon_OCR: 4,
  van: 9,
  flyer: 13,
  catalog: 42,
}

const styles = computed(() => {
  // @ts-ignore
  if (['sm', 'md', 'lg'].includes(props.size)) return {}
  return {
    '--font-size': props.size + 'px',
  }
})
const classes = computed(() => {
  return [
    `icon-${props.name}`,
    ['sm', 'md', 'lg'].includes(props.size) ? `xo-icon-${props.size}` : '',
    { 'xo-icon-clickable': props.clickable },
  ]
})

const emit = defineEmits<{
  (e: 'click', event: Event): void
}>()
const onClick = (e: Event) => {
  emit('click', e)
}
</script>

<template>
  <span
    ref="icon"
    class="xo-icon"
    :class="classes"
    :style="styles"
    @click="onClick"
  >
    <template v-if="addSpans[name]">
      <!-- アイコンの都合上必要な要素を追加 -->
      <span v-for="n of addSpans[name]" :key="n" :class="`path${n}`"></span>
    </template>
  </span>
</template>

<style lang="scss" scoped>
.xo-icon {
  font-size: var(--font-size);

  &-sm {
    font-size: 24px;
  }
  &-md {
    font-size: 32px;
  }
  &-lg {
    font-size: 48px;
  }

  &.xo-icon-clickable {
    cursor: pointer;
  }
}
</style>
