import { setActivePinia, createPinia } from 'pinia'

type RequestOptions = {
  responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer'
  headers?: HeadersInit
  params?: { [key: string]: string | number | undefined | null }
}

const runtimeConfig = useRuntimeConfig()

export const useHttpClient = (baseUrl?: string) => {
  // コンポーネント外でのStoreの使用時は、setActivePiniaを使ってPiniaインスタンスをストアに注入する必要がある
  setActivePinia(createPinia())

  const cacheStore = useCacheStore()
  const { getCachedData, setCache } = cacheStore

  const api = $fetch.create({
    baseURL: baseUrl || runtimeConfig.public.API_URL,
  })
  const accessToken = useCookie('auth:token') as Ref<string>

  /**
   *
   * @param headers
   * @returns HeadersInit
   */
  const generateHeaders = (headers: HeadersInit = {}): HeadersInit => {
    return {
      Authorization: `Bearer ${accessToken.value}`,
      ...headers,
    }
  }

  const $get = <T = any>(
    url: string,
    options: RequestOptions = {}
  ): Promise<T> => {
    try {
      options.headers = generateHeaders(options.headers)
      options.responseType = options.responseType || 'json'

      // options.paramsのすべての項目から、nullのものをundefinedに変換する
      options.params = Object.fromEntries(
        Object.entries(options.params || {}).map(([key, value]) => [
          key,
          value === null ? undefined : value,
        ])
      )

      // データストアにキャッシュがあればそれを返す
      const cacheKey = JSON.stringify({ url, params: options.params })
      const cachedData = getCachedData<T>(cacheKey)
      if (cachedData) return Promise.resolve(cachedData)

      const response = api(url, {
        method: 'GET',
        ...options,
      }) as Promise<T>
      setCache<T>(cacheKey, response)

      return response
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    $get,
  }
}
