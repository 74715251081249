import { Actions, Getters, State } from '~/types/store/cacheStore'

export const useCacheStore = defineStore<'cacheStore', State, Getters, Actions>(
  'cacheStore',
  {
    state: () => ({
      // キーはリクエストのURLとパラメータを組み合わせた文字列、値はレスポンスデータ
      cache: {},
    }),
    getters: {
      getCachedData() {
        return (key: string) => this.cache[key] || null
      },
    },
    actions: {
      setCache(key, data) {
        this.cache[key] = data
      },
    },
  }
)
